<template>
  <main class="container">
    <br />

    <div class="row">
      <div class="col-md-12">
        <div class="card rounded-lg border border-dark">
          <div class="row">
            <div class="col">
              <div class="row my-5 mx-4">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Invited Speakers</h5>
                    <hr />

                    <ul>
                      <li class="card-text">
                        Dr. Ali Nadir ARSLAN, Finnish Meteorological Institute,
                        FIN
                      </li>
                      <li class="card-text">
                        Dr. Aynur S. SORMAN, Eskisehir Technical University, TUR
                      </li>
                      <li class="card-text">
                        Dr. Daniele CASELLA, National Research Council of Italy, ITA
                      </li>
                      <li class="card-text">
                        Dr. İsmail YÜCEL, Middle East Technical University, TUR
                      </li>
                      <li class="card-text">
                        Dr. Neil I. FOX, University of Missouri, USA
                      </li>
                      <li class="card-text">
                        Dr. Samuel MILLER, Plymouth State University, USA
                      </li>
                      <li class="card-text">
                        Dr. Sante LAVIOLA, National Research Council of Italy,
                        ITA
                      </li>
                      <li class="card-text">
                        Dr. Semih EKERCIN, Necmettin Erbakan University, TUR
                      </li>
                      <li class="card-text">
                        Dr. Zuhal AKYÜREK, Middle East Technical University, TUR
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script></script>

<style scoped></style>
